const breakpoints = require('./grid.breakpoints');

module.exports = {
  DEFAULT: {
    maxContentWidth: '1320px',
    maxArticleWidth: '648px',
    gap: '12px',
    margin: '12px',
  },
  xs: {
    minWidth: breakpoints.xs + 'px',
  },
  sm: {
    minWidth: breakpoints.sm + 'px',
    gap: '18px',
    margin: '18px',
  },
  md: {
    minWidth: breakpoints.md + 'px',
  },
  lg: {
    minWidth: breakpoints.lg + 'px',
    gap: '24px',
    margin: '24px',
  },
  xl: {
    minWidth: breakpoints.xl + 'px',
  },
};
