const plugin = require('tailwindcss/plugin');

const notPlugin = plugin(({ matchVariant }) => {
  matchVariant(
    'not',
    (value, { modifier }) => {
      return `&:not(${modifier || value})`;
    },
    {
      values: {
        active: ':active',
        focus: ':focus',
        'focus-within': ':focus-within',
        hover: ':hover',
      },
    },
  );
});

module.exports = notPlugin;
