const { mergeWith } = require('lodash');

const baseConfig = require('./base/tailwind.js');
const siteConfig = require('./modules/tailwind/site.config.js');

const site = process.env.OCELOT_SITE;

const customizer = (a, b) => {
  if (Array.isArray(a)) {
    return a.concat(b);
  }
};

const content = [
  './base/components/**/*.{js,ts,jsx,tsx}',
  './lib/**/*.{js,ts,jsx,tsx}',
  './pages/**/*.{js,ts,jsx,tsx}',
  `./sites/${site}/components/**/*.{js,ts,jsx,tsx}`,
];

/** @type {import('tailwindcss').Config} */

const config = mergeWith({ content }, baseConfig, siteConfig, customizer);

module.exports = config;
