import { isServer } from '@/utils/isServer';
import { logger } from '@/utils/logger';
import { getOcelotConfig } from 'lib/configs/ocelot';

export const adsWinAma = () => {
  if (isServer()) return;

  return getOcelotConfig('ads.ama.enabled');
};

export const initAdunitElements = (adsUniqueId?: string, isNativeAd?: boolean, isSecondArticle?: boolean) => {
  if (isServer()) return;

  if (isNativeAd) {
    return;
  }

  if (adsWinAma()) {
    if (typeof window.ama?.init !== 'function') return;

    if (!adsUniqueId) {
      return logger.warn(`Ads unique ID is missing`);
    }

    window.ama.adsUniqueId = `${adsUniqueId}`;
    window.ama.domLoaded = window.ama.domLoaded || {};

    window.ama.init(adsUniqueId, { isSecondArticle });
  } else {
    // This part will be removed when all sites uses window.ama
    if (typeof window.DFPInit !== 'function') return;

    if (!adsUniqueId) {
      return logger.warn(`Ads unique ID is missing`);
    }

    window.adsUniqueId = `${adsUniqueId}`;
    window.DFPInit(adsUniqueId, { isSecondArticle });
  }
};
