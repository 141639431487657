import { Button } from '@/components/Button';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { isDev } from '@/utils/isDev';
import { tw } from '@/utils/tw';
import { useState } from 'react';
import { TbColumns3 } from 'react-icons/tb';

interface GridOverlayProps extends StandaloneComponentProps {}

export const GridOverlay: StandaloneComponent<GridOverlayProps> = (props) => {
  const [show, setShow] = useState(false);

  if (!isDev) return null;

  return (
    <>
      <Button
        content={<TbColumns3 />}
        options={{
          className: 'fixed right-4 bottom-4 z-[999] p-3 opacity-15 hover:opacity-100',
          onClick: () => setShow((state) => !state),
        }}
      />

      <section
        className={tw.join(
          'fixed',
          'z-[999]',
          'top-0',
          'left-1/2',
          '-translate-x-1/2',
          'grid',
          'grid-cols-12',
          'w-full',
          'h-full',
          'max-w-grid',
          'px-grid-m',
          'gap-x-grid',
          'bg-teal-500',
          'pointer-events-none',
          'transition-opacity',
          show ? 'opacity-20' : 'opacity-0',
        )}
      >
        {Array.from({ length: 12 }).map((_, index) => (
          <div key={index} className="bg-amber-500" />
        ))}
      </section>
    </>
  );
};
