import { StandaloneComponent } from '@/types/component';
import { getSite } from '@/utils/getSite';
import { isDev } from '@/utils/is/isDev';
import { getOcelotConfig } from 'lib/configs/ocelot';
import Head from 'next/head';
import Script from 'next/script';
import { useEffect } from 'react';

export const StandaloneConsent: StandaloneComponent = () => {
  const site = getSite();
  const consentVersion = `2.3.14${['svenskdam', 'hant'].includes(`${site}`) ? `${site}` : ''}`;
  const consentPath = `/common/js/consent`;
  const componentVersion = '05';
  const cacheBuster = `v=${consentVersion}-${componentVersion}`;
  const oneTrustId = getOcelotConfig('cmp.onetrust_id');

  const consentConfUrl = `${consentPath}/consent-conf.js?${cacheBuster}`;
  const otSdkUrl = `https://cdn.cookielaw.org/scripttemplates/otSDKStub.js`;
  const tcfStubUrl = `https://cdn.cookielaw.org/consent/tcf.stub.js`;
  const allerConsentUrl = `${consentPath}/consent-${consentVersion}.js`;

  const preload = getOcelotConfig('ads.preload.enabled');

  useEffect(() => {
    window.onetrustId = oneTrustId;
  }, []);

  if (process.env.CONSENT_DISABLED === 'true') {
    return null;
  }

  return (
    <>
      <Head>
        {/* Always preload tcf stub and otStub */}
        <link rel="preload" href={tcfStubUrl} as="script" />
        <link rel="preload" href={otSdkUrl} as="script" />
 
        { preload && (
          <>
            {!isDev && (
              <>
                <link rel="preload" href={consentConfUrl} as="script" />
              </>
            )}
            <link rel="preload" href={allerConsentUrl} as="script" />
          </>
        )}
      </Head>

      {/* Consent Stub containing tcfapi */}
      <Script data-cfasync="false" src={tcfStubUrl} strategy="beforeInteractive"/>

      {/* CMP - OneTrust consent */}
      <Script data-cfasync="false" src={otSdkUrl} data-document-language="true" data-domain-script={`${oneTrustId}`} strategy="beforeInteractive"/>

      {/* Lock bg-scroll for CMP banner */}
      {!isDev && <Script src={consentConfUrl} strategy={ preload ? 'afterInteractive' : 'beforeInteractive' }/> }

      {/* Aller consent library */}
      <Script src={allerConsentUrl} strategy={ preload ? 'afterInteractive' : 'beforeInteractive' }/>
    </>
  );
};
