const { omit, transform, merge } = require('lodash');
const plugin = require('tailwindcss/plugin');
const baseConfig = require('../../../base/configs/grid.system');

/** @type {import('@aller/helpers').GridSystemPlugin} */

const gridSystemPlugin = (brandConfig) => {
  const config = merge(baseConfig, { ...brandConfig });
  const screens = omit(config, 'DEFAULT');

  const { maxContentWidth, maxArticleWidth, margin, gap } = config.DEFAULT;

  const maxGap = `max(${Object.values(config)
    .map((values) => values.gap)
    .filter(Boolean)
    .join(', ')})`;

  const maxMargin = `max(${Object.values(config)
    .map((values) => values.margin)
    .filter(Boolean)
    .join(', ')})`;

  return plugin(
    ({ addBase, matchVariant }) => {
      addBase({
        ':root': {
          '--grid-gap': gap,
          '--grid-mx': margin,
          '--grid-max-w-content': maxContentWidth,
          '--grid-max-w-article': maxArticleWidth,
          '--grid-max-w': 'calc(var(--grid-max-w-content) + (var(--grid-mx) * 2))',
          '--grid-w': 'min(100vw, var(--grid-max-w))',
          '--grid-col': 'calc(var(--grid-w) - (var(--grid-mx) * 2) - (var(--grid-gap) * 11)) / 12',
          '--grid-cols-1': 'calc(var(--grid-col) * 1)',
          '--grid-cols-2': 'calc(var(--grid-col) * 2 + var(--grid-gap))',
          '--grid-cols-3': 'calc(var(--grid-col) * 3 + var(--grid-gap) * 2)',
          '--grid-cols-4': 'calc(var(--grid-col) * 4 + var(--grid-gap) * 3)',
          '--grid-cols-5': 'calc(var(--grid-col) * 5 + var(--grid-gap) * 4)',
          '--grid-cols-6': 'calc(var(--grid-col) * 6 + var(--grid-gap) * 5)',
          '--grid-cols-7': 'calc(var(--grid-col) * 7 + var(--grid-gap) * 6)',
          '--grid-cols-8': 'calc(var(--grid-col) * 8 + var(--grid-gap) * 7)',
          '--grid-cols-9': 'calc(var(--grid-col) * 9 + var(--grid-gap) * 8)',
          '--grid-cols-10': 'calc(var(--grid-col) * 10 + var(--grid-gap) * 9)',
          '--grid-cols-11': 'calc(var(--grid-col) * 11 + var(--grid-gap) * 10)',
          '--grid-cols-12': 'calc(var(--grid-col) * 12 + var(--grid-gap) * 11)',

          ...transform(
            screens,
            (result, values, breakpoint) => {
              result[`@screen ${breakpoint}`] = {
                '--grid-gap': values.gap,
                '--grid-mx': values.margin,
                '--grid-max-w-content': values.maxContentWidth,
                '--grid-max-w-article': values.maxArticleWidth,
              };
            },
            {},
          ),
        },

        '.grid-container': {
          'container-type': 'inline-size',
        },
      });

      matchVariant(
        'grid',
        (columns) =>
          // @container query doesn't support css variables, so we need to use non-responsive values
          `@container (min-width: calc(((min(100vw, ${maxContentWidth}) - (${maxMargin} * 2) - (${maxGap} * 11)) / 12 * ${columns}) + (${maxGap} * (${columns} - 1))))`,
        {
          values: {
            'cols-1': '1',
            'cols-2': '2',
            'cols-3': '3',
            'cols-4': '4',
            'cols-5': '5',
            'cols-6': '6',
            'cols-7': '7',
            'cols-8': '8',
            'cols-9': '9',
            'cols-10': '10',
            'cols-11': '11',
            'cols-12': '12',
          },
        },
      );
    },
    {
      theme: {
        extend: {
          screens: {
            ...transform(
              screens,
              (result, values, breakpoint) => {
                if (values.minWidth) {
                  result[breakpoint] = values.minWidth;
                }
              },
              {},
            ),
          },
          gap: {
            grid: 'var(--grid-gap)',
            'grid-1/2': 'calc(var(--grid-gap) / 2)',
            'grid-m': 'var(--grid-mx)',
            'grid-m-1/2': 'calc(var(--grid-mx) / 2)',
          },
          margin: {
            grid: 'var(--grid-mx)',
            'grid-1/2': 'calc(var(--grid-mx) / 2)',
            'grid-gap': 'var(--grid-gap)',
            'grid-gap-1/2': 'calc(var(--grid-gap) / 2)',
          },
          ...transform(
            ['spacing', 'borderWidth'],
            (result, prop) => {
              result[prop] = {
                'grid-m': 'var(--grid-mx)',
                'grid-m-1/2': 'calc(var(--grid-mx) / 2)',
                'grid-gap': 'var(--grid-gap)',
                'grid-gap-1/2': 'calc(var(--grid-gap) / 2)',
              };
            },
            {},
          ),
          ...transform(
            ['width', 'maxWidth', 'minWidth', 'height', 'maxHeight', 'minHeight'],
            (result, prop) => {
              result[prop] = {
                content: 'var(--grid-max-w-content)',
                article: 'var(--grid-max-w-article)',
                grid: 'var(--grid-max-w)',
                'cols-1': 'var(--grid-cols-1)',
                'cols-2': 'var(--grid-cols-2)',
                'cols-3': 'var(--grid-cols-3)',
                'cols-4': 'var(--grid-cols-4)',
                'cols-5': 'var(--grid-cols-5)',
                'cols-6': 'var(--grid-cols-6)',
                'cols-7': 'var(--grid-cols-7)',
                'cols-8': 'var(--grid-cols-8)',
                'cols-9': 'var(--grid-cols-9)',
                'cols-10': 'var(--grid-cols-10)',
                'cols-11': 'var(--grid-cols-11)',
                'cols-12': 'var(--grid-cols-12)',
              };
            },
            {},
          ),
        },
      },
    },
  );
};

module.exports = gridSystemPlugin;
