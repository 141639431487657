import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { jsonify } from '@/utils/jsonify';
import { getOcelotConfig } from 'lib/configs/ocelot';
import Script from 'next/script';
import Gtm from './Gtm';
import { Kilkaya } from './Kilkaya';
import { KilkayaMetadata } from './types';
interface AnalyticsConfig {
  tealium: {
    account: string;
    profile: string;
    environment: string;
  };
  avo: {
    url: string;
  };
  funnelRelay: {
    url: string;
  };
  tags: string[];
  jwplayer: boolean;
}

const publicToolsPath = '/common/js/tools';
const analyticsConfig = getOcelotConfig('analytics');
const ctsScriptUrl = `//cdn.aller.se/cts/v1.12.45/se-ocelot/bundle.js`;

const ctsConfig = (Object.keys(analyticsConfig) as (keyof typeof analyticsConfig)[]).reduce((acc, key) => {
  return { ...acc, [key]: analyticsConfig[key] };
}, {} as AnalyticsConfig);

export interface StandaloneTrackingProps extends StandaloneComponentProps {
  ctsData?: Record<string, string>;
  allerGaData?: Record<string, string>;
  kilkayaData?: KilkayaMetadata;
}

export const StandaloneTracking: StandaloneComponent<StandaloneTrackingProps> = ({
  ctsData,
  allerGaData,
  kilkayaData,
}) => {
  return (
    <>
      <Script id="first-party-cookie-handling" src={`${publicToolsPath}/fptools.js`} />

      <Kilkaya k5aMeta={kilkayaData}>
        {analyticsConfig?.google?.gtm && <Gtm gtmId={analyticsConfig?.google?.gtm} />}
      </Kilkaya>

      <Script
        id="cts-tracking"
        data-script-url={ctsScriptUrl}
        data-allerga={jsonify(allerGaData)}
        data-metadata={jsonify(ctsData)}
        data-config={jsonify(ctsConfig)}
        src={`${publicToolsPath}/cts.js`}
      />
    </>
  );
};
