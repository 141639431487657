const withBreakpointVariants = require('./withBreakpointVariants');

const withBackgroundColorVariants = (colors) => {
  return colors.flatMap((color) => withBreakpointVariants([`bg-${color}`, `wings-${color}`]));
};

const withTextColorVariants = (colors) => {
  return colors.flatMap((color) => withBreakpointVariants([`text-${color}`]));
};

module.exports = {
  withBackgroundColorVariants,
  withTextColorVariants,
};
