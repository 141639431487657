import { IconTheme } from '@/components/Icon/theme';
import { tw } from '@/utils/tw';

const Icon = tw.theme({
  extend: IconTheme,
  defaultVariants: {
    size: 'medium',
  },
});

const Accordion = tw.theme({
  slots: {
    base: ['group/accordion'],
    content: ['hidden', 'group-[[data-open]>]/accordion:block', 'md:block'],
    group: ['flex', 'items-center', 'justify-between'],
    toggle: [
      'cursor-pointer',
      'pr-4',
      'transition-transform',
      'group-[[data-open]>:first-child]/accordion:[transform:rotateX(180deg)]',
      'md:hidden',
    ],
    headline: [],
  },
  variants: {
    type: {
      rich: {
        base: ['p-3', 'md:p-6', 'shadow', 'w-full'],
        content: ['md:hidden', 'group-[[data-open]>]/accordion:block', 'mt-3', 'md:mt-5'],
        toggle: ['md:block', 'pr-0'],
      },
    },
  },
});

export default Object.assign(Accordion, { Icon });
