const logga = {
  300: '#FFF0F7',
  400: '#FFC9E3',
  500: '#FF8BC3',
  600: '#FF3D9A',
  700: '#E6007F',
  800: '#B1146A',
  900: '#5B0936',
};

const husfarg = {
  300: '#FFEEFF',
  400: '#FFCCFF',
  500: '#FF8CF3',
  600: '#FF5DF9',
  700: '#FF00FF',
  800: '#CA1ECA',
  900: '#A220A2',
};

const yellow = {
  500: '#FFFEEF',
  600: '#FEFCD7',
  700: '#FFFF99',
  800: '#FCEC5C',
  900: '#F9C50E',
};

const aquamarine = {
  300: '#F2FFF7',
  400: '#DBFFEB',
  500: '#C6FFE0',
  600: '#8AFFCE',
  700: '#33FFC2',
  800: '#3BD8A9',
  900: '#3CAA89',
};

const radicalRed = {
  300: '#FFE9EF',
  400: '#FFCCD9',
  500: '#FFA9BE',
  600: '#FF6E8F',
  700: '#FF3D69',
  800: '#D22049',
  900: '#B30A31',
};

const dodgerBlue = {
  300: '#F0F8FF',
  400: '#DBEEFF',
  500: '#B7DCFF',
  600: '#71B2FF',
  700: '#3380FF',
  800: '#1252BA',
  900: '#0D3578',
};

const success = {
  100: '#E9FBE9',
  200: '#D4F7D4',
  300: '#ABEEAA',
  400: '#82E380',
  500: '#5FD75D',
  600: '#3DC93B',
  700: '#1DBA1A',
  800: '#10950E',
  900: '#076C05',
};

const error = {
  100: '#FFDAD6',
  200: '#FFB4AB',
  300: '#FF897D',
  400: '#FF5449',
  500: '#DE3730',
  600: '#BA1A1A',
  700: '#93000A',
  800: '#690005',
  900: '#410002',
};

const gray = {
  50: '#F2F0F4',
  100: '#E3E2E6',
  200: '#C7C6CA',
  300: '#ACABAF',
  400: '#919094',
  500: '#77777A',
  600: '#5E5E62',
  700: '#46464A',
  800: '#303034',
  900: '#1B1B1F',
};

const colors = {
  logga: {
    DEFAULT: logga['700'],
    ...logga,
  },
  husfarg: {
    DEFAULT: husfarg['700'],
    ...husfarg,
  },
  yellow: {
    DEFAULT: yellow['700'],
    ...yellow,
  },
  aquamarine: {
    DEFAULT: aquamarine['700'],
    ...aquamarine,
  },
  'radical-red': {
    DEFAULT: radicalRed['700'],
    ...radicalRed,
  },
  'dodger-blue': {
    DEFAULT: dodgerBlue['700'],
    ...dodgerBlue,
  },
  success: {
    DEFAULT: success[600],
    ...success,
  },
  error: {
    DEFAULT: error[600],
    ...error,
  },
  gray: {
    DEFAULT: gray[600],
    ...gray,
  },
};

module.exports = colors;
