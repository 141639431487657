import { ButtonTheme } from '@/components/Button/theme';
import { tw } from '@/utils/tw';
import NavigationTheme from 'base/components/Navigation/theme';

const Button = tw.theme({
  extend: ButtonTheme,
  variants: {
    size: {
      small: ['md:px-5', 'md:gap-2', 'md:h-9', 'md:text-button-md'],
    },
  },
});

const BaseLinkVariants = NavigationTheme.Link.variants;

const Link = tw.theme({
  variants: {
    colors: {
      bar: ['text-gray-900', 'lg:text-gray-900'],
      drawer: ['text-gray-900'],
    },
    size: {
      bar: ['p-2.5', 'lg:p-2', 'text-body-md', 'px-3', 'py-1'],
      controls: BaseLinkVariants.size.controls,
      drawer: [
        'text-body-md',
        "[&[data-depth='1']]:text-body-lg",
        "[&:not([data-depth='1'])]:ml-4",
        'py-2.5',
        'sm:py-1.5',
      ],
    },
    variant: {
      bar: [
        '!font-normal',
        'hover:underline',
        'sm:aria-[current=page]:no-underline',
        'aria-[current=page]:underline',
        'aria-[current=page]:underline-offset-[12px]',
      ],
      drawerPrimary: ['block', "data-[depth='1']:!font-normal"],
      drawerSecondary: BaseLinkVariants.variant.drawerSecondary,
    },
  },
});

export default Object.assign({}, { Button, Link });
