const headline = {
  '2xl': {
    font: 'primary',
    size: '68px',
    weight: '275',
    leading: '1.147',
  },
  xl: {
    font: 'primary',
    size: '48px',
    weight: '275',
    leading: '1.17',
  },
  lg: {
    font: 'primary',
    size: '42px',
    weight: '275',
    leading: '1.19',
  },
  md: {
    font: 'primary',
    size: '32px',
    weight: '275',
    leading: '1.19',
  },
  sm: {
    font: 'primary',
    size: '22px',
    weight: '275',
    leading: '1.18',
  },
  xs: {
    font: 'primary',
    size: '16px',
    weight: '275',
    leading: '1.25',
  },
};

const specialHeadline = {
  lg: {
    font: 'tertiary',
    size: '32px',
    weight: '400',
    leading: '1',
  },
  sm: {
    font: 'tertiary',
    size: '24px',
    weight: '400',
    leading: '1',
  },
};

const quote = {
  lg: {
    font: 'primary',
    size: '44px',
    weight: '275',
    leading: '1.14',
  },
  md: {
    font: 'primary',
    size: '32px',
    weight: '275',
    leading: '1.18',
  },
};

const preamble = {
  md: {
    font: 'secondary',
    size: '20px',
    weight: '700',
    leading: '1.3',
  },
};

const paragraph = {
  md: {
    font: 'secondary',
    size: '18px',
    weight: '400',
    leading: '1.33',
  },
};

const body = {
  lg: {
    font: 'primary',
    size: '18px',
    weight: '275',
    leading: '1.33',
  },
  md: {
    font: 'primary',
    size: '15px',
    weight: '275',
    leading: '1.53',
  },
  sm: {
    font: 'primary',
    size: '12px',
    weight: '275',
    leading: '1.33',
  },
};

const button = {
  md: {
    font: 'primary',
    size: '18px',
    weight: '500',
    leading: '1',
  },
  sm: {
    font: 'primary',
    size: '14px',
    weight: '500',
    leading: '1.142',
  },
};

const category = {
  md: {
    font: 'primary',
    size: '14px',
    weight: '400',
    leading: '1.4',
  },
};

const typography = {
  headline: {
    DEFAULT: headline.md,
    ...headline,
  },
  'special-headline': {
    DEFAULT: specialHeadline.lg,
    ...specialHeadline,
  },
  quote: {
    DEFAULT: quote.lg,
    ...quote,
  },
  preamble: {
    DEFAULT: preamble.md,
    ...preamble,
  },
  paragraph: {
    DEFAULT: paragraph.md,
    ...paragraph,
  },
  body: {
    DEFAULT: body.md,
    ...body,
  },
  button: {
    DEFAULT: button.md,
    ...button,
  },
  category: {
    DEFAULT: category.md,
    ...category,
  },
};

module.exports = typography;
