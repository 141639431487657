const generateFractionSizes = ({ from = 2, to = 6 } = {}) => {
  const fractionSizes = {};

  for (let numerator = 1; numerator <= to - 1; numerator++) {
    for (let denominator = from; denominator <= to; denominator++) {
      if (numerator < denominator) {
        const fraction = `${numerator}/${denominator}`;
        fractionSizes[fraction] = ((100 / denominator) * numerator).toFixed(2) + '%';
      }
    }
  }

  return fractionSizes;
};

module.exports = generateFractionSizes;
