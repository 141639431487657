const typographyPlugin = require('tailwind-utils/typography.plugin');

module.exports = (typographyConfig) => (theme) => {
  const typograph = (name) => typographyPlugin.getUtilities(theme, typographyConfig)[`.text-${name}`];

  return {
    DEFAULT: {
      css: {
        '--tw-prose-body': theme('colors.black'),
        '--tw-prose-headings': theme('colors.black'),
        '--tw-prose-bullets': theme('colors.black'),
        '--tw-prose-counters': theme('colors.black'),
        '--tw-prose-links': theme('colors.logga.600'),

        'max-width': '100%',

        ...typograph('paragraph'),

        h1: {
          ...typograph('headline-sm'),
          marginTop: theme('spacing[4]'),
          marginBottom: theme('spacing[2]'),
        },

        h2: {
          ...typograph('headline-sm'),
          marginTop: theme('spacing[4]'),
          marginBottom: theme('spacing[2]'),
        },

        h3: {
          ...typograph('headline-xs'),
          marginTop: theme('spacing[4]'),
          marginBottom: theme('spacing[2]'),
        },

        h4: {
          ...typograph('headline-xs'),
          marginTop: theme('spacing[4]'),
          marginBottom: theme('spacing[2]'),
        },

        h5: {
          ...typograph('headline-xs'),
          marginTop: theme('spacing[4]'),
          marginBottom: theme('spacing[2]'),
        },

        h6: {
          ...typograph('headline-xs'),
          marginTop: theme('spacing[4]'),
          marginBottom: theme('spacing[2]'),
        },

        a: {
          textDecoration: 'none',

          '&:hover': {
            color: theme('colors.logga.700'),
            textDecoration: 'underline',
          },
          '&:visited': {
            color: theme('colors.logga.800'),
          },
        },

        ol: { marginBottom: theme('spacing[3]') },

        ul: { marginBottom: theme('spacing[3]') },

        li: {
          ...typograph('body-lg'),
          margin: 0,
          paddingLeft: 0,

          '&::marker': {
            fontSize: '13px',
          },
        },

        p: {
          ...typograph('paragraph'),

          '> span.big-letter': {
            fontSize: theme('fontSize.6xl[0]'),
            lineHeight: 1,
            fontWeight: theme('fontWeight.bold'),
            marginRight: theme('spacing[1]'),
            float: 'left',
          },
        },
      },
    },

    factbox: {
      css: {
        h2: {
          ...typograph('headline-xs'),
          marginTop: theme('spacing[4.5]'),
          marginBottom: 0,
        },
        h3: {
          ...typograph('headline-xs'),
          marginTop: theme('spacing[4.5]'),
          marginBottom: 0,
        },
        p: {
          ...typograph('body-md'),
          marginTop: theme('spacing[2]'),
          marginBottom: 0,
        },
        ul: {
          paddingLeft: theme('spacing[6]'),
          marginTop: theme('spacing[4]'),
          marginBottom: 0,
        },
        ol: {
          paddingLeft: theme('spacing[6]'),
          marginTop: theme('spacing[4]'),
          marginBottom: 0,
        },
        li: {
          ...typograph('body-md'),
          marginTop: 0,

          p: {
            display: 'inline',
          },

          '&::marker': {
            fontWeight: 300,
          },
        },
      },
    },

    coverbox: {
      css: {
        p: {
          ...typograph('body-sm'),
          marginBottom: 0,

          '@media screen(sm)': typograph('body-md'),
        },
        a: {
          fontWeight: 400,
          color: theme('colors.black'),
          '&:hover': {
            color: theme('colors.logga.600'),
          },
        },
      },
    },

    'author-details': {
      css: {
        p: typograph('body-md'),

        a: {
          fontWeight: 500,
        },
      },
    },

    'newsletter-signup-modal': {
      css: {
        h2: {
          marginTop: 0,
          marginBottom: theme('spacing[3]'),
        },

        p: typograph('body-sm'),
      },
    },
  };
};
