export interface Cookies {
  [key: string]: string | undefined;
  _ga?: string;
}

export const getCookiesFromString = (cookiesString: string) => {
  const cookiesList = cookiesString.split(';');

  return cookiesList.reduce((acc, cookie) => {
    const [key, value] = cookie.split('=');

    if (!key) return acc;

    return {
      ...acc,
      [key?.trim()]: value?.trim(),
    };
  }, {} as Cookies);
};
