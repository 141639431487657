const gridSystemPlugin = require('tailwind-utils/gridSystem.plugin');
const typographyPlugin = require('tailwind-utils/typography.plugin');
const { withBackgroundColorVariants } = require('tailwind-utils/withColorVariants');

const typographyConfig = require('./configs/tailwind.typography');
const colorsConfig = require('./configs/tailwind.colors');
const proseConfig = require('./configs/tailwind.prose');

const fontFamily = {
  primary: `var(--font-primary), Arial, sans-serif`,
  secondary: `var(--font-secondary), Arial, sans-serif`,
  tertiary: `var(--font-tertiary), Arial, sans-serif`,
};

/** @type {import('tailwindcss').Config} */

module.exports = {
  theme: {
    extend: {
      fontFamily,
      colors: colorsConfig,

      typography: proseConfig(typographyConfig),
    },
  },

  safelist: [
    ...withBackgroundColorVariants([
      'aquamarine-300',
      'dodger-blue-300',
      'aquamarine-300',
      'dodger-blue-300',
      'dodger-blue-400',
      'husfarg-300',
      'logga-300',
      'radical-red-300',
      'yellow-600',
    ]),
  ],

  plugins: [gridSystemPlugin(), typographyPlugin(typographyConfig)],
};
