const withExtendedValues = ({ base, from, precision, limit, step, unit }) => {
  const cfg = {
    base: base ?? 4,
    from: from ?? 0,
    precision: precision ?? 4,
    limit: limit ?? 1,
    step: step ?? 0.25,
    unit: unit ?? 'rem',
  };

  let result = {};

  for (let idx = 0; idx < cfg.limit; idx++) {
    const key = cfg.from + cfg.step * idx;
    const value = Number((key / cfg.base).toFixed(cfg.precision));
    result[key] = `${value}${cfg.unit}`;
  }

  return result;
};

module.exports = withExtendedValues;
