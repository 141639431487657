const tailwindAnimatedPlugin = require('tailwindcss-animated');
const tailwindClipPathPlugin = require('tailwind-clip-path');
const tailwindScrollbarPlugin = require('tailwind-scrollbar')({ nocompatible: true });
const tailwindTypographyPlugin = require('@tailwindcss/typography');

const notPlugin = require('tailwind-utils/not.plugin');
const ordinalNumbersPlugin = require('tailwind-utils/ordinalNumbers.plugin');
const wingsPlugin = require('tailwind-utils/wings.plugin');

const withExtendedValues = require('tailwind-utils/withExtendedValues');
const withFractionSizes = require('tailwind-utils/withFractionSizes');
const { withBackgroundColorVariants, withTextColorVariants } = require('tailwind-utils/withColorVariants');
const withBreakpointVariants = require('tailwind-utils/withBreakpointVariants');

/** @type {import('tailwindcss').Config} */

module.exports = {
  theme: {
    extend: {
      maxWidth: {
        ...withExtendedValues({ limit: 120, step: 1 }),
        ...withFractionSizes(),
      },
      minWidth: {
        ...withExtendedValues({ limit: 120, step: 1, from: 0 }),
        ...withFractionSizes(),
      },
      maxHeight: {
        ...withExtendedValues({ limit: 120, step: 1 }),
        ...withFractionSizes(),
      },
      minHeight: {
        ...withExtendedValues({ limit: 120, step: 1, from: 0 }),
        ...withFractionSizes(),
      },
      padding: {
        ...withExtendedValues({ limit: 20, step: 1 }),
      },
      margin: {
        ...withExtendedValues({ limit: 20, step: 1 }),
      },
      boxShadow: {
        1: '0 0 0 1px',
        2: '0 0 0 2px',
        4: '0 0 0 4px',
        'inset-0': 'inset 0 0 0 0px',
        'inset-1': 'inset 0 0 0 1px',
        'inset-2': 'inset 0 0 0 2px',
      },
      spacing: {
        ...withExtendedValues({ limit: 100 }),
        ...withExtendedValues({ limit: 120, step: 1 }),
        ...withFractionSizes(),
      },
      lineHeight: {
        ...withExtendedValues({ limit: 28, from: 3 }),
      },
      outlineWidth: {
        ...withExtendedValues({ limit: 10, from: 1, step: 1, base: 1, unit: 'px' }),
      },
      zIndex: {
        ...withExtendedValues({ limit: 20, step: 1, base: 1, precision: 0, unit: '' }),
        menu: 100,
        'article-disclosure': 50,
      },
      borderWidth: {
        ...withExtendedValues({ limit: 20, from: 1, step: 1, base: 1, unit: 'px' }),
      },
      rotate: {
        ...withExtendedValues({ limit: 48, step: 15, base: 1, unit: 'deg' }),
      },
      keyframes: {
        'blip-inner-circle': {
          '0%': { transform: 'scale(1)', opacity: 0.4 },
          '80%, 100%': { transform: 'scale(4)', opacity: 0 },
        },
        'blip-outer-circle': {
          '0%': { transform: 'scale(1)', opacity: 0.4 },
          '80%, 100%': { transform: 'scale(6)', opacity: 0 },
        },
        'pulse-ring': {
          '0%': { transform: 'scale(0.53)' },
          '80%, 100%': { opacity: 0 },
        },
        loop: {
          from: { transform: 'translateX(var(--loop-from))' },
          to: { transform: 'translateX(var(--loop-to))' },
        },
        skeleton: {
          from: { left: '0%' },
          to: { left: '100%' },
        },
      },
      animation: {
        'blip-inner-circle': 'blip-inner-circle 2s ease-out infinite',
        'blip-outer-circle': 'blip-outer-circle 2s ease-out infinite',
        'pulse-ring': 'pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite',
        loop: 'loop var(--loop-duration) linear infinite',
        skeleton: 'skeleton 1s ease infinite',
      },
      aspectRatio: {
        vertical: '9 / 16',
        default: '1.9170305676856 / 1',
      },
    },
  },

  safelist: [
    ...withBreakpointVariants([
      'w-12',
      'w-1/12',
      'w-2/12',
      'w-3/12',
      'w-4/12',
      'w-5/12',
      'w-6/12',
      'w-7/12',
      'w-8/12',
      'w-9/12',
      'w-10/12',
      'w-11/12',
      'w-full',
      'hidden',
      'font-black',
      'text-center',
      'text-right',
      'text-left',
      'bold',
      'italic',
      'underline',
      'uppercase',
    ]),
    ...withBackgroundColorVariants(['white', 'black']),
    ...withTextColorVariants(['white', 'black']),
  ],

  plugins: [
    notPlugin,
    ordinalNumbersPlugin,
    wingsPlugin,
    tailwindScrollbarPlugin,
    tailwindClipPathPlugin,
    tailwindTypographyPlugin,
    tailwindAnimatedPlugin,
  ],
};
