const plugin = require('tailwindcss/plugin');

const nth = {
  first: 1,
  second: 2,
  third: 3,
  fourth: 4,
  fifth: 5,
  sixth: 6,
  seventh: 7,
  eighth: 8,
  ninth: 9,
};

const ordinalNumbersPlugin = plugin(function ({ addVariant }) {
  Object.entries(nth).forEach(([key, value]) => {
    addVariant(key, `&:nth-child(${value})`);
    addVariant(`${key}-of-type`, `&:nth-of-type(${value})`);
  });
});

module.exports = ordinalNumbersPlugin;
