import type { LinkProps } from '@/components/Link';
import { Link } from '@/components/Link';
import { NavigationTheme } from '@/components/Navigation/theme';
import { useComponentsState } from '@/hooks/useComponentsState';
import { ElementKey, StandaloneComponent } from '@/types/component';
import { getUrl } from '@/utils/getUrl';
import { mergeProps } from '@/utils/merge';
import { TrackingProps, withTrackingAttributes } from '@/utils/withTrackingAttributes';
import { GenericSlot, GenericSlotRender } from 'base/components/GenericSlot';
import { useRouter } from 'next/router';

interface NavigationLinkProps extends LinkProps {
  as?: ElementKey;
  tracking?: TrackingProps;
}

export type NavigationLink = StandaloneComponent<NavigationLinkProps>;

const Render: GenericSlotRender<NavigationLink> = ({ props: { as, tracking, ...props } }) => {
  const router = useRouter();

  const {
    Navigation: {
      $drawer: { hide },
    },
  } = useComponentsState();
  const trackingAttrs = withTrackingAttributes(tracking);

  if (as) {
    const Proxy = GenericSlot({ as, theme: NavigationTheme.Link });
    const { content, options, ...proxyProps } = props;

    return (
      <Proxy {...{ options }} {...trackingAttrs} {...proxyProps}>
        {content}
      </Proxy>
    );
  }

  return (
    <Link
      {...trackingAttrs}
      {...mergeProps(
        {
          options: {
            colors: 'bar',
            size: 'bar',
            variant: 'bar',
            onClick: hide,
          },
        } as const,
        props,
      )}
      aria-current={getUrl(router.asPath)?.pathname === props.href ? 'page' : undefined}
    />
  );
};

export default {
  render: Render,
};
